import { CardMedia } from '@mui/material';
import { MuiCard } from 'common';

const VideoCard = () => (
	<MuiCard header='Latest video'>
		<CardMedia
			frameBorder='0'
			allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
			allowFullScreen
			component='iframe'
			height={450}
			src='https://www.youtube.com/'
			title='YouTube video player'
		/>
	</MuiCard>
);

export default VideoCard;
